import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import '../../dist/css/bolt-css.css';
import { iconsNames } from '../../dist/js/bolt';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Nota: ícones multicores, como é o caso dos cartões, são divididos em partes. Então sua composição segue o padrão a seguir:`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div className="tw-i-master">
  <span className="path1" />
  <span className="path2" />
  <span className="path3" />
  <span className="path4" />
</div>
`}</code></pre>
    <Playground __position={0} __code={'() => {\n  return (\n    <div className=\"tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-5 tw-gap-4\">\n      {iconsNames.map(name => (\n        <div className=\"tw-flex tw-flex-col tw-items-center\">\n          <div className={`${name} tw-text-4xl`}>\n            <span className=\"path1\"></span>\n            <span className=\"path2\"></span>\n            <span className=\"path3\"></span>\n            <span className=\"path4\"></span>\n          </div>\n          <p className=\"tw-body2\">{`${name}`}</p>\n        </div>\n      ))}\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      iconsNames,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        return <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-5 tw-gap-4">
        {iconsNames.map(name => <div className="tw-flex tw-flex-col tw-items-center">
            <div className={`${name} tw-text-4xl`}>
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </div>
            <p className="tw-body2">{`${name}`}</p>
          </div>)}
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      